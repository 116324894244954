import React from 'react';
import { Link } from '@remix-run/react';

import Button, { ButtonProps } from '~/components/Button';
import { IconName } from '~/components/Icon';
import { useSound } from '~/hooks/useSound';
import { cn } from '~/utils/cn';

const MainLink = ({
  to,
  label,
  icon,
  variant,
  soon
}: {
  to: string;
  label: string;
  icon: IconName;
  variant?: ButtonProps['variant'];
  soon?: boolean;
}) => {
  const playSound = useSound('/sounds/click_003.mp3');

  const handleClick = () => {
    playSound();
  };

  return (
    <Link
      to={soon ? '#' : to}
      className={cn(
        'flex gap-3 main-link max-md:items-center group/text',
        soon && 'cursor-not-allowed opacity-50'
      )}
      data-cy={`${label}-link`}
      onClick={handleClick}
    >
      <Button
        as="div"
        size="small"
        className={cn(
          'size-8 md:size-9 rotate-[4deg] *:mb-0.5 [.main-link:nth-child(odd)_&]:rotate-[-4deg] rounded-lg p-0 before:rounded-lg after:rounded-lg',
          soon && 'pointer-events-none'
        )}
        icon={icon}
        variant={variant}
        aria-hidden
      />
      <span
        className={cn(
          !soon && 'transition-transform group-hover/text:translate-x-1',
          soon && 'pointer-events-none'
        )}
      >
        <span className="h-xl md:h-3xl">{label}</span>
      </span>
      {soon && (
        <span className="h-base relative self-start !font-nanum text-[20px] font-normal lowercase md:text-[30px]">
          Soon...
        </span>
      )}
    </Link>
  );
};

export default MainLink;
