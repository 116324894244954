import React, { useEffect, useState } from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import { intervalToDuration, startOfTomorrow } from 'date-fns';
import { AnimatePresence, motion } from 'motion/react';

import background from '~/assets/images/bg-home.jpeg';
import transparentPattern from '~/assets/images/pattern-transparent.png';
import About from '~/components/About';
import Icon from '~/components/Icon';
import MainMenuLink from '~/components/MainMenuLink';
import Modal from '~/components/Modal';
import Toast from '~/components/Toast';
import { useEnv } from '~/hooks/useEnv';
import { useWebPush } from '~/hooks/useWebPush';
import { ENV } from '~/types';
import { cn } from '~/utils/cn';
import { envLoader } from '~/utils/env';

export const loader = envLoader;

export default function Index() {
  const env = useLoaderData<ENV>();
  useEnv(env);

  const [timeLeft, setTimeLeft] = useState<string | null>(null);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const {
    isSubscribing,
    isSubscribed,
    error: errorWebPush,
    success: successWebPush,
    requestPermissions: requestPermissionsWebPush
  } = useWebPush();

  const hideToast = () => {
    setShowToast(false);
  };

  const getWebPushButtonText = () => {
    if (isSubscribing) return 'Subscribing...';
    if (isSubscribed) return 'Subscribed to daily reminder';
    return 'Add a daily reminder';
  };

  const getNextGameTime = () => {
    const duration = intervalToDuration({
      start: new Date(),
      end: startOfTomorrow()
    });
    const hours = duration.hours?.toString().padStart(2, '0') ?? '00';
    const minutes = duration.minutes?.toString().padStart(2, '0') ?? '00';
    const seconds = duration.seconds?.toString().padStart(2, '0') ?? '00';

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getNextGameTime());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Update error and success states when webpush states change
  useEffect(() => {
    if (successWebPush) setShowToast(true);
  }, [successWebPush]);

  useEffect(() => {
    if (errorWebPush) setShowToast(true);
  }, [errorWebPush]);

  return (
    <div className="relative flex size-full flex-col px-4 pb-safe-offset-6 pt-safe-offset-36 md:px-10 md:pb-safe-offset-10">
      {/* Background */}
      <div className="absolute inset-0 z-0 size-full">
        <div
          className="absolute inset-0 z-10 size-full"
          style={{
            backgroundImage: `url(${transparentPattern})`
          }}
        />
        <div className="left-gradient absolute inset-0 z-10 size-full" />
        <motion.img
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 3,
            ease: 'circOut'
          }}
          src={background}
          alt="background"
          className="size-full object-cover opacity-50"
        />
      </div>

      {/* Main links */}
      <div className="relative flex w-fit flex-col gap-3 uppercase">
        <MainMenuLink
          to="/daily"
          label="Guess of the day"
          icon="question"
          variant="green"
        />
        <MainMenuLink
          to="#"
          label="Past 30 days"
          icon="calendar"
          soon
          variant="blue"
        />
        <MainMenuLink
          to="#"
          label="Survival mode"
          icon="skull"
          soon
          variant="red"
        />
      </div>

      {/* Secondary links */}
      <div className="relative mt-auto flex flex-col gap-2 max-md:mb-6">
        <button
          type="button"
          onClick={() => setIsAboutModalOpen(true)}
          className="h-base flex items-center gap-2"
        >
          <Icon name="smiley" size={6} className="mb-px" />
          About us
        </button>

        {/* WebPush Request Permissions */}
        {/* @TODO: Service Worker isn't supported on this browser, disable or hide UI. */}
        <button
          type="button"
          data-cy="webpush-request-permissions"
          className={cn(
            'h-base flex items-center gap-2',
            isSubscribing || isSubscribed ? 'opacity-50' : ''
          )}
          onClick={requestPermissionsWebPush}
          disabled={isSubscribing || isSubscribed}
        >
          <Icon name="calendar" size={6} className="mb-px" />
          {getWebPushButtonText()}
        </button>

        <AnimatePresence>
          {showToast && (
            <>
              {successWebPush && (
                <Toast
                  body="Successfully subscribed to daily notifications"
                  onHide={hideToast}
                />
              )}
              {errorWebPush && <Toast body={errorWebPush} onHide={hideToast} />}
            </>
          )}
        </AnimatePresence>

        <Link
          to="#"
          className="h-base pointer-events-none flex items-center gap-2 opacity-50"
        >
          <Icon name="handStar" size={6} className="mb-px" />
          Help us
        </Link>
      </div>

      {/* Time left */}
      <div
        data-cy="next-daily-riddle-timer"
        className={cn(
          'right-10 bottom-10 z-10 flex w-fit flex-col gap-3 rounded-lg bg-black/25 p-2 text-center backdrop-blur-[32px] md:absolute transition-opacity duration-300',
          timeLeft ? 'opacity-100' : 'opacity-0'
        )}
      >
        <span className="text-sm">
          New riddle in{' '}
          <span className="inline-block w-15 whitespace-nowrap text-left">
            {timeLeft}
          </span>
        </span>
      </div>

      <Modal
        isOpen={isAboutModalOpen}
        onClose={() => setIsAboutModalOpen(false)}
      >
        <About />
      </Modal>
    </div>
  );
}
