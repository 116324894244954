import React, { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'motion/react';

import pattern from '~/assets/images/pattern.png';
import Button from '~/components/Button';
import { useSound } from '~/hooks/useSound';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const playMaximize = useSound('/sounds/maximize_008.mp3');
  const playMinimize = useSound('/sounds/minimize_008.mp3');

  useEffect(() => {
    if (isOpen) {
      playMaximize();
    }
  }, [isOpen, playMaximize]);

  useEffect(() => {
    if (!isOpen || !modalRef.current) return undefined;

    const focusableElements = modalRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusable = focusableElements[0] as HTMLElement;
    const lastFocusable = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    const handleTab = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusable) {
          e.preventDefault();
          lastFocusable.focus();
        }
      } else if (document.activeElement === lastFocusable) {
        e.preventDefault();
        firstFocusable.focus();
      }
    };

    document.addEventListener('keydown', handleTab);
    firstFocusable?.focus();

    return () => {
      document.removeEventListener('keydown', handleTab);
    };
  }, [isOpen]);

  const handleClose = () => {
    playMinimize();
    onClose();
  };

  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', onEscape);
    } else {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', onEscape);
    }

    return () => {
      document.removeEventListener('keydown', onEscape);
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={modalRef}
          role="dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: 'spring',
            bounce: 0,
            stiffness: 120,
            damping: 15
          }}
          className="fixed inset-0 isolate z-50 flex overflow-y-auto bg-purple-800 backdrop-blur-2xl md:bg-gray-500 md:p-6"
          onClick={handleClose}
        >
          <motion.div
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{
              type: 'spring',
              bounce: 0,
              stiffness: 120,
              damping: 15
            }}
            className="relative flex h-fit min-h-full w-full flex-col [overflow:clip] md:rounded-2xl"
            onClick={e => e.stopPropagation()}
          >
            <Button
              onClick={handleClose}
              variant="purple"
              data-cy="close-modal"
              className="sticky right-4 z-20 ml-auto top-safe-offset-4 md:right-6 md:top-safe-offset-6"
              size="small"
              icon="close"
            >
              Close modal
            </Button>
            <div className="relative z-10 mx-auto max-w-lg px-4 pb-10 pt-safe-offset-4 md:px-6 md:pb-28 md:pt-safe-offset-18">
              {children}
            </div>
            <div
              className="circular-gradient absolute inset-x-0 top-0 h-full"
              style={{ backgroundImage: `url(${pattern})` }}
            >
              <div className="circular-gradient absolute inset-0" />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
